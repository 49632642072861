<template>
  <el-container class="profile">
    <el-row :gutter="20">
      <el-col :span="24" style="margin-bottom:60px;">
        <h2 v-for="text in $tm('company.profile.welcomeText')" :key="text">{{ text }}</h2>
      </el-col>
      <el-col :span="12">
        <el-card>
          <template #header>
            <el-icon>
              <i class="bi bi-link-45deg"></i>
            </el-icon>
            <span>{{ $t("company.profile.resources.title") }}</span>
          </template>
          <template #>
            <ul class="resource-list">
              <li v-for="obj in $tm('company.profile.resources.links')" :key="obj.name">
                <el-link @click="openLink(obj.link)">{{ obj.name }}</el-link>
              </li>
            </ul>
          </template>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<style scoped>
.profile {
  margin: 10px 20px;
  width: 100%;
}

.el-icon {
  margin-right: 10px;
}

.profile>>>.el-card__body {
  padding: 0;
}

.resource-list>li {
  padding-left: 10px;
}
</style>

<script>
export default {
  name: "CompanyProfile",
  methods: {
    openLink(link) {
      window.open(link, "_blank")
    }
  }
}
</script>
